import { Text } from "@clipboard-health/ui-react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Box, useTheme } from "@mui/material";
import pluralize from "pluralize";
import { type ReactElement } from "react";

interface AccountsRequirementAccordionProps {
  children: ReactElement;
  isCollapsible: boolean;
  description?: string;
  requirementCount: number;
  groupTitle: string;
  isExpanded?: boolean;
  onAccordionExpanded?: () => void;
}

export function AccountsRequirementAccordion(
  props: AccountsRequirementAccordionProps
): ReactElement {
  const {
    children,
    isCollapsible,
    description,
    requirementCount,
    groupTitle,
    isExpanded = false,
    onAccordionExpanded,
  } = props;

  const theme = useTheme();
  return (
    <Accordion
      square
      disableGutters
      expanded={!isCollapsible || isExpanded}
      onChange={onAccordionExpanded}
    >
      <AccordionSummary expandIcon={isCollapsible ? <ExpandMoreIcon fontSize="large" /> : null}>
        <Text variant="h2">{groupTitle}</Text>
        {isCollapsible && (
          <Text sx={{ marginLeft: 1 }}>({pluralize("document", requirementCount, true)})</Text>
        )}
      </AccordionSummary>
      <AccordionDetails>
        {description && (
          <Text
            sx={{
              color: theme.palette.grey[700],
            }}
          >
            {description}
          </Text>
        )}
        <Box sx={{ marginTop: 2 }}>{children}</Box>
      </AccordionDetails>
    </Accordion>
  );
}
